import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { HeaderTabProps } from './Header';
import { ReactComponent as Logo } from '../assets/global/Logo.svg';
import { ReactComponent as CloseIcon } from '../assets/Header/CloseIcon.svg';
import { GilroyTypo } from '../StyledComponents/Typographies';

import { NavLink } from 'react-router-dom';
import { IconButton } from '@mui/material';

interface Props {
  open: Boolean;
  setOpenDrawer: Function;
  HeaderTabs: Array<HeaderTabProps>;
}

export default function CustomDrawer({
  open,
  setOpenDrawer,
  HeaderTabs,
}: Props) {
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const list = (HeaderTabs: Array<HeaderTabProps>) => (
    <Box
      sx={{
        width: 250,
        background:
          'linear-gradient(135deg, rgba(196, 167, 79, 0.75) 0%, rgba(187, 74, 45, 0.75) 100%)',
        color: 'black',
        height: '100vh',
      }}
      role='presentation'
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 1,
        }}
      >
        <NavLink
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            background: 'black',
            padding: '8px 16px',
            borderRadius: '25px',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleCloseDrawer()}
          onKeyDown={() => handleCloseDrawer()}
          to='/'
        >
          <Logo style={{ height: 24, width: 24 }} />
          <GilroyTypo
            sx={{
              color: '#FCD535',
              fontSize: { xs: 16, sm: 20, md: 24 },
              fontWeight: 600,
            }}
          >
            CDEX
          </GilroyTypo>
        </NavLink>

        <IconButton
          sx={{
            display: 'grid',
            placeItems: 'center',
            padding: '10px ',
          }}
          onClick={handleCloseDrawer}
          onKeyDown={handleCloseDrawer}
        >
          <CloseIcon style={{ cursor: 'pointer' }} />
        </IconButton>
      </Box>
      <List sx={{ my: 4, p: 0, borderTop: '1px solid white' }}>
        {HeaderTabs.map((item: HeaderTabProps, index: number) => (
          <NavLink
            style={{
              textDecoration: 'none',
              color: 'black',
            }}
            key={index}
            to={item.nav}
            onClick={() => handleCloseDrawer()}
            onKeyDown={() => handleCloseDrawer()}
          >
            <ListItem
              key={index}
              disablePadding
              sx={{ borderBottom: '1px solid white' }}
            >
              <ListItemButton>
                <ListItemText primary={item.title} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/* <Button onClick={toggleDrawer(true)}>{anchor}</Button> */}
        <Drawer
          anchor={'right'}
          open={Boolean(open)}
          onClose={handleCloseDrawer}
        >
          {list(HeaderTabs)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
