import React, { useState } from 'react';
import HeaderBg from '../assets/Header/HeaderBg.png';
import { Box, Button, IconButton } from '@mui/material';
import { ReactComponent as Logo } from '../assets/global/Logo.svg';
import { ReactComponent as HamBurger } from '../assets/Header/HamBurger.svg';
import { GilroyTypo, RedHatTypo } from '../StyledComponents/Typographies';
import { NavLink } from 'react-router-dom';
import CustomDrawer from './Drawer';

export interface HeaderTabProps {
  title: string;
  id: number;
  nav: string;
}

const HeaderTabs: Array<HeaderTabProps> = [
  {
    id: 1,
    title: 'Spot Trading',
    nav: '/spot_trading',
  },
  {
    id: 2,
    title: 'P2P',
    nav: '/p2p',
  },
  {
    id: 3,
    title: 'Learn',
    nav: '/learn',
  },
  {
    id: 4,
    title: 'About',
    nav: '/about',
  },
  {
    id: 5,
    title: 'Exchange',
    nav: '/exchange',
  },
  {
    id: 6,
    title: 'Inbox',
    nav: '/inbox',
  },
];

const TabComponent = ({ title, nav, id }: HeaderTabProps) => {
  return (
    <NavLink
      style={{
        textDecoration: 'none',
      }}
      to={nav}
    >
      {({ isActive, isPending }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: { xs: '8vh', md: '10vh' },
          }}
        >
          <RedHatTypo
            sx={{
              flexGrow: isActive ? 1 : 0,
              color: '#FCD535',
              display: 'flex',
              alignItems: 'center',
              minWidth: '100px',
              justifyContent: 'center',
            }}
          >
            {title}
          </RedHatTypo>
          <Box
            sx={{
              flexGrow: 0,
              height: '5px',
              width: 'auto',
              backgroundColor: isActive ? '#FCD535' : 'transparent',
              mb: '2px',
              borderRadius: '5px',
            }}
          />
        </Box>
      )}
    </NavLink>
  );
};

interface Props {
  children: React.ReactNode;
}

const Header = ({ children }: Props) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <Box
        sx={{
          height: '10vh',
          width: '100%',
          position: 'relaive',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box sx={{ position: 'absolute' }}>
          <CustomDrawer
            open={openDrawer}
            setOpenDrawer={setOpenDrawer}
            HeaderTabs={HeaderTabs}
          />
        </Box>
        <img
          src={HeaderBg}
          alt='headerBg'
          style={{
            width: 'inherit',
            height: 'inherit',
            position: 'absolute',
            zIndex: -1,
          }}
        />
        <Box
          sx={{
            height: 'inherit',
            width: { xs: '95%', sm: '90%', md: '95%', lg: '90%' },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <NavLink
            style={{
              height: 'inherit',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              textDecoration: 'none',
            }}
            to='/'
          >
            <Logo style={{ height: '55%', width: 'auto' }} />
            <GilroyTypo
              sx={{
                color: '#FCD535',
                fontSize: { xs: 16, sm: 20, md: 24 },
                fontWeight: 600,
              }}
            >
              CDEX
            </GilroyTypo>
          </NavLink>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: { xs: 0, md: 0, lg: '4px' },
            }}
          >
            {HeaderTabs.map((item) => (
              <div key={item.id}>
                <TabComponent title={item.title} nav={item.nav} id={item.id} />
              </div>
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button
              variant='contained'
              sx={{
                borderRadius: '40px',
                display: { xs: 'none', md: 'block' },
                fontWeight: 700,
                color: '#BB4A2D',
                fontSize: { xs: 12 },
              }}
            >
              Connect Wallet
            </Button>
            <Button
              variant='contained'
              size='small'
              sx={{
                borderRadius: '40px',
                display: { xs: 'block', md: 'none' },
                fontSize: { xs: 10, md: 'auto' },
                fontWeight: 700,
                color: '#BB4A2D',
              }}
            >
              Connect Wallet
            </Button>

            <IconButton
              sx={{
                display: { xs: 'grid', md: 'none' },
                placeItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setOpenDrawer(true)}
            >
              <HamBurger />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: '90vh', overflowY: 'auto' }}>{children}</Box>
    </Box>
  );
};

export default Header;
