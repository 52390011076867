import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import { RedHatTypo } from '../../StyledComponents/Typographies';
import { ReactComponent as SortIcon } from '../../assets/global/SortIcon.svg';
import Question from '../../assets/P2P/Question.png';

const options = [
  { id: 1, option: 'BUY' },
  { id: 2, option: 'SELL' },
];

const P2pData = ['25.02', '12.2', '12.2', '05.22', '05.22', '05.22'];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: 220, md: 400 },
  height: 250,
  boxShadow: 24,
  color: 'white',
};

const P2pContent = () => {
  const [selectedOption, setSelectedOption] = useState<number>(1);

  const handleOptionSelect = (id: number) => {
    if (selectedOption !== id) {
      setSelectedOption(id);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleAction = () => setOpen(true);
  const handleActionClose = () => setOpen(false);

  const [paymentOpen, setPaymentOpen] = useState(false);
  const handlePaymentAction = () => setPaymentOpen(true);
  const handlePaymentActionClose = () => setPaymentOpen(false);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minWidth: 750,
      }}
    >
      <Box
        sx={{
          mt: { xs: 3, md: 6 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'white',
          width: '90%',
        }}
      >
        <RedHatTypo>{selectedOption === 1 ? 'BUY' : 'SELL'}</RedHatTypo>
        <Box sx={{ display: 'flex', userSelect: 'none' }}>
          <RedHatTypo
            sx={{
              py: 1,
              px: 4,
              background: selectedOption === 1 ? '#72622C' : 'transparent',
              border:
                selectedOption === 1 ? '1px solid #72622C' : '1px solid white',
              borderRadius: '6px 0 0 6px',
              fontSize: 14,
              fontWeight: 600,
              cursor: selectedOption !== 1 ? 'pointer' : 'default',
            }}
            onClick={() => handleOptionSelect(1)}
          >
            BUY
          </RedHatTypo>
          <RedHatTypo
            sx={{
              py: 1,
              px: 4,
              background: selectedOption === 2 ? '#72622C' : 'transparent',
              border:
                selectedOption === 2 ? '1px solid #72622C' : '1px solid white',
              borderRadius: ' 0 6px 6px 0',
              fontSize: 14,
              fontWeight: 600,
              cursor: selectedOption !== 2 ? 'pointer' : 'default',
            }}
            onClick={() => handleOptionSelect(2)}
          >
            SELL
          </RedHatTypo>
        </Box>

        <Button variant='contained' sx={{ color: '#BB4A2D', fontWeight: 700 }}>
          Request {selectedOption === 1 ? 'Buy' : 'Sell'}
        </Button>
      </Box>
      <Box
        sx={{
          color: ' white',
          width: { xs: '90%', md: '80%' },
          mt: { xs: 4, md: 6 },
          borderWidth: '2px',
          borderStyle: 'solid',
          borderBottom: 'none',
          borderImage: 'linear-gradient(#fad66a, transparent) 50',
          p: 2,
          mb: 4,
          display: 'flex',
          flexDirection: ' column',
          gap: 2,
          height: '100%',
          justifyContent: 'space-evenly',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '25% 25% 25% 25%',
            placeItems: 'center',
            borderRadius: '5px 5px 0 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <RedHatTypo sx={{ fontSize: 14, fontWeight: 700 }}>
              Price
            </RedHatTypo>
            <SortIcon />
          </Box>
          <RedHatTypo sx={{ fontSize: 14, fontWeight: 700 }}>Limit</RedHatTypo>
          <RedHatTypo sx={{ fontSize: 14, fontWeight: 700 }}>
            Payment
          </RedHatTypo>
        </Box>
        {P2pData.map((item, index) => (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '25% 25% 25% 25%',
              placeItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                alignItems: 'center',
              }}
            >
              <RedHatTypo sx={{ fontSize: 14, fontWeight: 700 }}>
                {item} $
              </RedHatTypo>
            </Box>
            <Box>
              <RedHatTypo sx={{ fontSize: 14, fontWeight: 500 }}>
                Available 77.25USDT
              </RedHatTypo>
              <RedHatTypo sx={{ fontSize: 12, fontWeight: 400 }}>
                Limit $55.0 - $75
              </RedHatTypo>
            </Box>
            <RedHatTypo
              sx={{
                background: 'rgba(217, 217, 217, 0.08)',
                px: 3,
                py: 1,
                fontSize: 14,
                borderRadius: '10px',
                color: index === 0 ? 'rgba(255, 215, 120)' : '#66C37B',
              }}
            >
              {index === 0 ? 'Bank Transfer' : 'WeChat'}
            </RedHatTypo>
            <Button
              variant='contained'
              sx={{ color: '#BB4A2D', fontWeight: 700 }}
              onClick={() => handleAction()}
            >
              {selectedOption === 1 ? 'Buy' : 'Sell'}
            </Button>
          </Box>
        ))}
      </Box>

      <>
        <div>
          <Modal
            open={open}
            onClose={() => handleActionClose()}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box
              sx={{ p: { xs: '16px', md: '32px' }, ...style }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px',
                backgroundColor: 'rgba(78, 78, 78, 0.50)',
                borderTop: '8px solid #FCD86B',
                backdropFilter: 'blur(15px)',
              }}
            >
              <Box
                sx={{
                  height: '90%',
                  display: 'flex',
                  flexDirection: { xs: 'column-reverse', md: 'row' },
                  justifyContent: { xs: 'normal', md: 'space-between' },
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: { xs: 'center', md: 'normal' },
                    gap: { xs: 1, md: 0 },
                    width: { xs: '100%', md: '50%' },
                  }}
                >
                  <RedHatTypo
                    sx={{
                      lineHeight: { xs: 1, md: 4 },
                      fontSize: { xs: 18, md: 25 },
                      fontWeight: 700,
                    }}
                  >
                    Are You Sure?
                  </RedHatTypo>
                  <RedHatTypo sx={{ fontSize: { xs: 12, md: 14 } }}>
                    Are you willing to buy 3 USDT
                  </RedHatTypo>
                  <RedHatTypo sx={{ fontSize: { xs: 12, md: 14 } }}>
                    Your request sent to buyer
                  </RedHatTypo>
                </Box>
                <Box
                  sx={{
                    width: { xs: '100%', md: '50%' },
                    height: { xs: 100, md: '100%' },
                  }}
                >
                  <img
                    src={Question}
                    alt='questionImg'
                    style={{
                      objectFit: 'contain',
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: '40% 40%',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  fullWidth
                  variant='contained'
                  sx={{ borderRadius: '40px' }}
                  onClick={() => {
                    handlePaymentAction();
                    handleActionClose();
                  }}
                >
                  Yes
                </Button>
                <Button
                  fullWidth
                  variant='outlined'
                  sx={{ borderRadius: '40px' }}
                  onClick={() => handleActionClose()}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </>

      <>
        <div>
          <Modal
            open={paymentOpen}
            onClose={() => handlePaymentActionClose()}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box
              sx={{
                ...style,
                width: { xs: 220, md: 500 },
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '4px',
                background: '#4E4E4E90',
              }}
            >
              <RedHatTypo
                sx={{
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.46)',
                  textAlign: 'center',
                  py: 0.5,
                  fontWeight: 700,
                }}
              >
                PAYMENT
              </RedHatTypo>
              <RedHatTypo
                sx={{
                  width: '100%',
                  textAlign: 'center',
                  py: 0.5,
                  fontWeight: 700,
                }}
              >
                Completed 0.00 USDT of 8.00 USDT
              </RedHatTypo>
            </Box>
          </Modal>
        </div>
      </>
    </Box>
  );
};

export default P2pContent;
