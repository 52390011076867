import React from 'react';
import Header from '../Components/Header';
import LandingPageSection1 from '../Components/LandingPage/LandingPageSection1';
import LandingPageSection2 from '../Components/LandingPage/LandingPageSection2';
import LandingPageSection3 from '../Components/LandingPage/LandingPageSection3';
import LandingPageSection4 from '../Components/LandingPage/LandingPageSection4';
import Footer from '../Components/Footer';
import { Box } from '@mui/material';

const LandingPage = () => {
  return (
    <>
      <Header>
        <Box sx={{ background: 'black' }}>
          <LandingPageSection1 />
          <LandingPageSection2 />
          <LandingPageSection3 />
          <LandingPageSection4 />
          <Footer />
        </Box>
      </Header>
    </>
  );
};

export default LandingPage;
