import { Box } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { GilroyTypo, RedHatTypo } from '../StyledComponents/Typographies';
import { ReactComponent as Logo } from '../assets/global/Logo.svg';
import { FooterLinks, FooterLinksSchema } from './FooterData';

const Footer = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        placeItems: 'center',
        background: 'black',
      }}
    >
      <Box sx={{ width: '90%', color: 'white', pt: 6, pb: 1 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '100%',
              sm: 'auto auto auto',
              md: 'auto auto auto auto auto',
              lg: '40% 15% 15% 15% 15%',
            },
            gap: { xs: 4, lg: 0 },
            placeItems: 'flex-start',
          }}
        >
          <NavLink
            style={{
              height: 'inherit',
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              textDecoration: 'none',
            }}
            to='/'
          >
            <Logo style={{ height: 40, width: 'auto' }} />
            <GilroyTypo
              sx={{
                color: '#FCD535',
                fontSize: { xs: 16, sm: 20, md: 24 },
                fontWeight: 600,
              }}
            >
              CDEX
            </GilroyTypo>
          </NavLink>

          {FooterLinks.map((item) => (
            <Box
              key={item.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: 'inherit',
                gap: 2,
              }}
            >
              <RedHatTypo sx={{ fontWeight: 700 }}>{item.title}</RedHatTypo>
              {item.data.map((linkItem: FooterLinksSchema) => (
                <NavLink
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                  to={linkItem.link}
                >
                  {item.id === 4 && (
                    <img src={linkItem?.icon} alt={linkItem.name} />
                  )}
                  {linkItem.name}
                </NavLink>
              ))}
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            borderTop: '1px solid #ccc',
            mt: 4,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            gap: { xs: 2, md: 0 },
            pt: 2,
          }}
        >
          <Box sx={{ fontSize: { xs: 12, md: 14 }, display: 'flex', gap: 4 }}>
            <NavLink to='#' style={{ textDecoration: 'none', color: 'white' }}>
              Privacy Policy
            </NavLink>
            <NavLink to='#' style={{ textDecoration: 'none', color: 'white' }}>
              Terms of Use
            </NavLink>
          </Box>

          <GilroyTypo sx={{ fontSize: { xs: 12, md: 14 }, textAlign: 'end' }}>
            © All rights reserved.
          </GilroyTypo>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
